<footer
  class="container"
  [ngClass]="{ 'footer-profiel': state.selectedPage === 'mijnprofiel' }"
>
  <!-- social icons -->
  <div class="row">
    <div class="col-md-5">
      <img
        class="footer-logo"
        src="/assets/svg/wwa_logo_footer.svg"
        width="100"
      />
    </div>
    <div class="col-md-4">
      <p class="ico-label">Volg ons op</p>
      <ul class="ico-list">
        <li>
          <a
            class="ico facebook"
            href="https://www.facebook.com/WerkWeb-Autisme-318192022370290/"
            target="_blank"
            >Facebook</a
          >
        </li>
        <li>
          <a
            class="ico twitter"
            href="https://twitter.com/WerkwebA"
            target="_blank"
            >Twitter</a
          >
        </li>
        <li>
          <a
            class="ico youtube"
            href="https://www.youtube.com/channel/UC_gXKVMKdLXmhF2ZHFIDCIg"
            target="_blank"
            >YouTube</a
          >
        </li>
        <li>
          <a
            class="ico instagram"
            href="https://www.instagram.com/werkwebautisme/"
            target="_blank"
            >Instagram</a
          >
        </li>
      </ul>
    </div>
    <div class="col-md-3 contact-col">
      <p class="ico-label">Contact</p>
      <ul class="ico-list">
        <li><a class="ico email" href="mailto:werkweb@autisme.nl">Email</a></li>
      </ul>
    </div>
  </div>

  <!-- logo's -->
  <div class="row">
    <div class="col-md-12 logosRow">
      <span class="footerLogo autismefonds"
        ><img src="/assets/img/logos/autismefonds.png"
      /></span>
      <span class="footerLogo"
        ><img src="/assets/img/logos/instituutgak.png"
      /></span>
      <span class="footerLogo"><img src="/assets/img/logos/NVA.png" /></span>
      <span class="footerLogo samenDoen"
        ><img src="/assets/img/logos/samendoen.png"
      /></span>
      <span class="footerLogo"><img src="/assets/img/logos/tgo.png" /></span>
      <span class="footerLogo"><img src="/assets/img/logos/umcg.png" /></span>
      <span class="footerLogo"><img src="/assets/img/logos/zonmw.png" /></span>
      <span class="footerLogo"
        ><img src="/assets/img/logos/zorginnovatie.png"
      /></span>
    </div>
  </div>

  <div class="row copyright">
    <div class="col-md-12">
      <a routerLink="/page/disclaimer">Disclaimer</a
      ><span class="pipe">&nbsp;|&nbsp;</span>
      <a routerLink="/page/privacyverklaring">Privacy</a
      ><span class="pipe">&nbsp;|</span> Deze website is ontwikkeld door
      <a href="https://www.carapaxit.nl" target="_blank">Carapax IT</a>
    </div>
  </div>
</footer>
