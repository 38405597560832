import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../services/state.service';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [NgClass, RouterLink]
})
export class FooterComponent implements OnInit {

  constructor(public state: StateService) {  }

  ngOnInit() {
  }
}
