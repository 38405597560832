import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { CmsService } from '../../../services/cms.service';
import { WpHoofdMenu } from '../../../models/wp-hoofd-menu';
import { WpMenuIds } from '../../../models/wp-menu';
import { StateService } from '../../../services/state.service';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserService } from '../../../generated';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
    imports: [NgIf, FormsModule, RouterLink, NgFor]
})
export class HeaderMenuComponent implements OnInit {

  wpHoofdMenu: WpHoofdMenu = { items: [] };
  wpLevensFases = { onderwijs: { name: '' }, werkzoeken: { name: '' }, werkhebben: { name: '' }, uitgeschakeld: { name: '' } };
  isUserLoggedIn = false;
  isUserAdmin = false;
  currentUsername = '';
  mobileMenuOpen = false;
  desktopSearchOpen = false;
  subMenuOpen = null;
  // view childs
  @ViewChild('mobileMenuToggle', { static: true }) mobileMenuToggle: ElementRef;
  @ViewChild('mobileMenu', { static: true }) mobileMenu: ElementRef;
  @ViewChild('desktopMenu', { static: true }) desktopMenu: ElementRef;
  @ViewChild('desktopSearchLi', { static: true }) desktopSearchLi: ElementRef;
  @ViewChild('desktopSearchBox', { static: true }) desktopSearchBox: ElementRef;
  @ViewChild('desktopSearchInput', { static: true }) desktopSearchInput: ElementRef;
  @ViewChild('mobileSearchInput', { static: true }) mobileSearchInput: ElementRef;
  @ViewChild('l1', { static: true }) l1: ElementRef;
  @ViewChild('l2', { static: true }) l2: ElementRef;
  @ViewChild('l3', { static: true }) l3: ElementRef;
  @ViewChild('themeToggler', { static: true }) themeToggler: ElementRef;
  @ViewChild('themeTogglerMobile', { static: false }) themeTogglerMobile: ElementRef;
  selectedTheme = 'colored';

  @Input() desktop = false;

  @HostListener('window:scroll') onScroll() {
    if (this.mobileMenuToggle && !this.mobileMenuOpen) {
      if (window.scrollY > 10) {
        this.r2.setStyle(this.mobileMenuToggle.nativeElement, 'box-shadow', '0px 0px 2px 2px rgba(0,0,0,0.1)');
      } else {
        this.r2.setStyle(this.mobileMenuToggle.nativeElement, 'box-shadow', 'none');
      }
    }
  }

  constructor(
    private cmsService: CmsService,
    public state: StateService,
    private userService: UserService,
    private authService: AuthService,
    private r2: Renderer2,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.cmsService.getHoofdMenuTree().subscribe((menu) => {
      this.wpHoofdMenu = menu;
    });
    this.cmsService.getLevensFaseNames().subscribe(menus => {
      this.wpLevensFases.onderwijs = menus[WpMenuIds.onderwijs];
      this.wpLevensFases.werkhebben = menus[WpMenuIds.werkhebben];
      this.wpLevensFases.werkzoeken = menus[WpMenuIds.werkzoeken];
      this.wpLevensFases.uitgeschakeld = menus[WpMenuIds.uitgeschakeld];
    });
  }

  ngOnInit() {
    this.setTheme();
    this.authService.loginStatus().subscribe(r => {
      if (r !== null) {
        this.isUserLoggedIn = true;
        this.state.isLoggedIn = true;
        this.currentUsername = r.profile.name;
        this.isUserAdmin = this.authService.hasUserRole('admin');
        if (this.isUserLoggedIn && this.state.isCoach === undefined) {
          this.userService.userProfileGet().subscribe((profile) => {
            this.state.isCoach = profile.isCoach;
          });
        }
        if (this.state.redirectAfterLogin) {
          this.state.redirectAfterLogin = false;
          if (this.isUserAdmin) {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigate(['/mijnprofiel']);
          }
        }
      } else { // user logged out
        // this.router.navigate(['/']);
        this.state.isLoggedIn = false;
      }
    });
  }

  /**
   * Switch tussen kleuren dmv het icoon naast de zoekicoon
   */
  setTheme(toggle = false) {
    const htmlElement = document.getElementsByTagName('body')[0];
    let svg = '';
    let themeToSet = this.selectedTheme;
    if (toggle) {
      if (this.selectedTheme === 'colored') {
        themeToSet = 'black-and-white';
      } else {
        themeToSet = 'colored';
      }
    }
    if (themeToSet === 'colored') {
      this.selectedTheme = themeToSet;
      htmlElement.classList.remove('black-and-white');
      svg = 'url(\'/assets/svg/black_and_white.svg\')';
    } else {
      this.selectedTheme = themeToSet;
      htmlElement.classList.remove('colored');
      svg = 'url(\'/assets/svg/colored.svg\')';
    }
    if (this.themeToggler) {
      this.r2.setStyle(this.themeToggler.nativeElement, 'background-image', svg);
    }
    htmlElement.classList.add(this.selectedTheme);
    this.state.selectedTheme = this.selectedTheme;
  }

  toggleMobileMenu(event = null) {
    if (event) {
      event.stopPropagation();
    }
    const l1 = this.l1.nativeElement;
    const l2 = this.l2.nativeElement;
    const l3 = this.l3.nativeElement;
    const m = this.mobileMenu.nativeElement;

    if (this.mobileMenuOpen) {
      this.r2.setStyle(l1, 'transform', 'rotate(0deg)');
      this.r2.setStyle(l1, 'top', '12px');
      this.r2.setStyle(l2, 'opacity', 1);
      this.r2.setStyle(l3, 'transform', 'rotate(0deg)');
      this.r2.setStyle(l3, 'top', '28px');
      this.r2.setStyle(m, 'right', '-100vw');
      this.r2.setStyle(m, 'box-shadow', 'none');
      if (window.scrollY > 10) {
        this.r2.setStyle(this.mobileMenuToggle.nativeElement, 'box-shadow', '0px 0px 2px 2px rgba(0,0,0,0.1)');
      } else {
        this.r2.setStyle(this.mobileMenuToggle.nativeElement, 'box-shadow', 'none');
      }
      this.toggleMobileSubMenu();
    } else {
      this.r2.setStyle(l1, 'transform', 'rotate(45deg)');
      this.r2.setStyle(l1, 'top', '20px');
      this.r2.setStyle(l2, 'opacity', 0);
      this.r2.setStyle(l3, 'transform', 'rotate(-45deg)');
      this.r2.setStyle(l3, 'top', '20px');
      this.r2.setStyle(m, 'display', 'flex');
      this.r2.setStyle(m, 'right', '0px');
      this.r2.setStyle(m, 'box-shadow', '0px 15px 24px 0px rgba(0,0,0,0.3)');
      this.r2.setStyle(this.mobileMenuToggle.nativeElement, 'box-shadow', 'none');
    }
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  toggleMobileSubMenuIfMenuHasChildren($event, item) {
    if (item.children.length > 0) {
      this.toggleMobileSubMenu($event, 'sub-menu-' + item.slug);
    } else {
      this.toggleMobileMenu($event);
      this.router.navigate(['/page/' + item.slug]);
    }
  }

  toggleMobileSubMenu($event = null, title: string = null) {
    if ($event) {
      $event.stopPropagation();
    }
    let sm;
    if (this.subMenuOpen) {
      // hide current subMenuOpen
      sm = document.getElementById(this.subMenuOpen);
      sm.style.setProperty('right', '-100vw');
    }
    if (title) {
      // show new subMenu
      this.subMenuOpen = title;
      sm = document.getElementById(title);
      sm.style.setProperty('right', 0);
    }
  }

  /**
   * Toggle zoekbalk in het menu
   */
  toggleSearchBox($event) {
    if ($event) {
      $event.stopPropagation();
    }
    const box = this.desktopSearchBox.nativeElement;
    if (this.desktopSearchOpen) {
      this.r2.setStyle(box, 'display', 'none');
    } else {
      this.r2.setStyle(box, 'display', 'block');
    }
    this.desktopSearchOpen = !this.desktopSearchOpen;
  }

  /**
   * Redirect naar zoekpagina na klikken op Zoeken of enter drukken
   */
  search() {
    let query;
    if (window.innerWidth >= 1180) {
      query = this.desktopSearchInput.nativeElement.value;
      this.r2.setStyle(this.desktopSearchBox.nativeElement, 'display', 'none');
      this.desktopSearchOpen = false;
    } else if (this.mobileSearchInput) {
      query = this.mobileSearchInput.nativeElement.value;
    }
    if (query.length === 0) { return; }
    if (this.mobileMenuOpen) {
      this.toggleMobileMenu();
    }
    this.router.navigate(['search', query]);
  }

  /**
   * Forceer zoeken na drukken op Enter
   */
  onKeydown($event) {
    if ($event.key === 'Enter') {
      this.search();
    }
  }

  /**
   * Toon IDS inlogmodal na klikken op inloggen
   */
  showAuthModal() {
    this.state.redirectAfterLogin = true;
    if (this.mobileMenuOpen) {
      this.toggleMobileMenu();
    }
    if (!this.isUserLoggedIn) {
      this.authService.requestLogin();
    }
  }

  doLogout() {
    if (this.isUserLoggedIn) {
      if (this.state.selectedPage === 'mijnprofiel' && this.state.myprofileComponent) {
        this.state.myprofileComponent.saveOnLogout(() => {
          this.authService.requestLogout();
        });
      } else {
        this.authService.requestLogout();
      }
    }
  }

  hasUserRole(role: string) {
    return this.isUserLoggedIn && this.authService.hasUserRole(role);
  }
}
