/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { CategoryDTO } from "../model/categoryDTO";
import { ChoiceSetDTO } from "../model/choiceSetDTO";
import { NextQuestionDTO } from "../model/nextQuestionDTO";
import { NextQuestionPostDTO } from "../model/nextQuestionPostDTO";
import { PostQuestionDTO } from "../model/postQuestionDTO";
import { ProgressDTO } from "../model/progressDTO";
import { QuestionAndAnswersDTO } from "../model/questionAndAnswersDTO";
import { QuestionDTO } from "../model/questionDTO";
import { QuestionnaireDTO } from "../model/questionnaireDTO";
import { QuestionnaireGroupAndQuestionnaireDTO } from "../model/questionnaireGroupAndQuestionnaireDTO";
import { QuestionnaireGroupDTO } from "../model/questionnaireGroupDTO";
import { ReportDTO } from "../model/reportDTO";
import { UpsertAnswerDTO } from "../model/upsertAnswerDTO";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class QuestionnaireService {
  protected basePath = "http://localhost";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireCategoryCreatePost(
    body?: CategoryDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public questionnaireCategoryCreatePost(
    body?: CategoryDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public questionnaireCategoryCreatePost(
    body?: CategoryDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public questionnaireCategoryCreatePost(
    body?: CategoryDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.basePath}/questionnaire/category/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireCategoryDeleteIdDelete(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireCategoryDeleteIdDelete(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireCategoryDeleteIdDelete(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireCategoryDeleteIdDelete(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireCategoryDeleteIdDelete."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/questionnaire/category/delete/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireCategoryListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<CategoryDTO>>;
  public questionnaireCategoryListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<CategoryDTO>>>;
  public questionnaireCategoryListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<CategoryDTO>>>;
  public questionnaireCategoryListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<CategoryDTO>>(
      `${this.basePath}/questionnaire/category/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireCategoryUpdatePut(
    body?: CategoryDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireCategoryUpdatePut(
    body?: CategoryDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireCategoryUpdatePut(
    body?: CategoryDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireCategoryUpdatePut(
    body?: CategoryDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/questionnaire/category/update`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireChoiceSetCreatePost(
    body?: ChoiceSetDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public questionnaireChoiceSetCreatePost(
    body?: ChoiceSetDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public questionnaireChoiceSetCreatePost(
    body?: ChoiceSetDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public questionnaireChoiceSetCreatePost(
    body?: ChoiceSetDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.basePath}/questionnaire/choiceSet/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireChoiceSetDeleteIdDelete(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireChoiceSetDeleteIdDelete(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireChoiceSetDeleteIdDelete(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireChoiceSetDeleteIdDelete(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireChoiceSetDeleteIdDelete."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/questionnaire/choiceSet/delete/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireChoiceSetListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ChoiceSetDTO>>;
  public questionnaireChoiceSetListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ChoiceSetDTO>>>;
  public questionnaireChoiceSetListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ChoiceSetDTO>>>;
  public questionnaireChoiceSetListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ChoiceSetDTO>>(
      `${this.basePath}/questionnaire/choiceSet/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireChoiceSetReadIdGet(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ChoiceSetDTO>;
  public questionnaireChoiceSetReadIdGet(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ChoiceSetDTO>>;
  public questionnaireChoiceSetReadIdGet(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ChoiceSetDTO>>;
  public questionnaireChoiceSetReadIdGet(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireChoiceSetReadIdGet."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ChoiceSetDTO>(
      `${this.basePath}/questionnaire/choiceSet/read/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireChoiceSetUpdatePut(
    body?: ChoiceSetDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireChoiceSetUpdatePut(
    body?: ChoiceSetDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireChoiceSetUpdatePut(
    body?: ChoiceSetDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireChoiceSetUpdatePut(
    body?: ChoiceSetDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/questionnaire/choiceSet/update`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireGroupCreatePost(
    body?: QuestionnaireGroupDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public questionnaireGroupCreatePost(
    body?: QuestionnaireGroupDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public questionnaireGroupCreatePost(
    body?: QuestionnaireGroupDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public questionnaireGroupCreatePost(
    body?: QuestionnaireGroupDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.basePath}/questionnaire/group/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireGroupDeleteIdPost(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireGroupDeleteIdPost(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireGroupDeleteIdPost(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireGroupDeleteIdPost(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireGroupDeleteIdPost."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<any>(
      `${this.basePath}/questionnaire/group/delete/${encodeURIComponent(
        String(id)
      )}`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireGroupListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<QuestionnaireGroupDTO>>;
  public questionnaireGroupListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<QuestionnaireGroupDTO>>>;
  public questionnaireGroupListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<QuestionnaireGroupDTO>>>;
  public questionnaireGroupListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<QuestionnaireGroupDTO>>(
      `${this.basePath}/questionnaire/group/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireGroupReadIdGet(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<QuestionnaireGroupDTO>;
  public questionnaireGroupReadIdGet(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<QuestionnaireGroupDTO>>;
  public questionnaireGroupReadIdGet(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<QuestionnaireGroupDTO>>;
  public questionnaireGroupReadIdGet(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireGroupReadIdGet."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<QuestionnaireGroupDTO>(
      `${this.basePath}/questionnaire/group/read/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireGroupUpdatePost(
    body?: QuestionnaireGroupDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireGroupUpdatePost(
    body?: QuestionnaireGroupDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireGroupUpdatePost(
    body?: QuestionnaireGroupDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireGroupUpdatePost(
    body?: QuestionnaireGroupDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/questionnaire/group/update`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireGroupsAndQuestionnairesGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<QuestionnaireGroupAndQuestionnaireDTO>>;
  public questionnaireGroupsAndQuestionnairesGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<QuestionnaireGroupAndQuestionnaireDTO>>>;
  public questionnaireGroupsAndQuestionnairesGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<QuestionnaireGroupAndQuestionnaireDTO>>>;
  public questionnaireGroupsAndQuestionnairesGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<QuestionnaireGroupAndQuestionnaireDTO>>(
      `${this.basePath}/questionnaire/groups-and-questionnaires`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireNextquestionsGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<NextQuestionDTO>>;
  public questionnaireNextquestionsGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<NextQuestionDTO>>>;
  public questionnaireNextquestionsGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<NextQuestionDTO>>>;
  public questionnaireNextquestionsGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<NextQuestionDTO>>(
      `${this.basePath}/questionnaire/nextquestions`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireNextquestionsPost(
    body?: Array<NextQuestionPostDTO>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireNextquestionsPost(
    body?: Array<NextQuestionPostDTO>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireNextquestionsPost(
    body?: Array<NextQuestionPostDTO>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireNextquestionsPost(
    body?: Array<NextQuestionPostDTO>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/questionnaire/nextquestions`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireProgressGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ProgressDTO>;
  public questionnaireProgressGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ProgressDTO>>;
  public questionnaireProgressGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ProgressDTO>>;
  public questionnaireProgressGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProgressDTO>(
      `${this.basePath}/questionnaire/progress`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionCreatePost(
    body?: PostQuestionDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public questionnaireQuestionCreatePost(
    body?: PostQuestionDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public questionnaireQuestionCreatePost(
    body?: PostQuestionDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public questionnaireQuestionCreatePost(
    body?: PostQuestionDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.basePath}/questionnaire/question/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionDeleteIdDelete(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireQuestionDeleteIdDelete(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireQuestionDeleteIdDelete(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireQuestionDeleteIdDelete(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireQuestionDeleteIdDelete."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/questionnaire/question/delete/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<QuestionDTO>>;
  public questionnaireQuestionListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<QuestionDTO>>>;
  public questionnaireQuestionListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<QuestionDTO>>>;
  public questionnaireQuestionListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<QuestionDTO>>(
      `${this.basePath}/questionnaire/question/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionUpdatePut(
    body?: PostQuestionDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireQuestionUpdatePut(
    body?: PostQuestionDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireQuestionUpdatePut(
    body?: PostQuestionDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireQuestionUpdatePut(
    body?: PostQuestionDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/questionnaire/question/update`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionnaireCreatePost(
    body?: QuestionnaireDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public questionnaireQuestionnaireCreatePost(
    body?: QuestionnaireDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public questionnaireQuestionnaireCreatePost(
    body?: QuestionnaireDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public questionnaireQuestionnaireCreatePost(
    body?: QuestionnaireDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.basePath}/questionnaire/questionnaire/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionnaireDeleteIdPost(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireQuestionnaireDeleteIdPost(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireQuestionnaireDeleteIdPost(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireQuestionnaireDeleteIdPost(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireQuestionnaireDeleteIdPost."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<any>(
      `${this.basePath}/questionnaire/questionnaire/delete/${encodeURIComponent(
        String(id)
      )}`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionnaireListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<QuestionnaireDTO>>;
  public questionnaireQuestionnaireListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<QuestionnaireDTO>>>;
  public questionnaireQuestionnaireListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<QuestionnaireDTO>>>;
  public questionnaireQuestionnaireListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<QuestionnaireDTO>>(
      `${this.basePath}/questionnaire/questionnaire/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionnaireReadIdGet(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<QuestionnaireDTO>;
  public questionnaireQuestionnaireReadIdGet(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<QuestionnaireDTO>>;
  public questionnaireQuestionnaireReadIdGet(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<QuestionnaireDTO>>;
  public questionnaireQuestionnaireReadIdGet(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireQuestionnaireReadIdGet."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<QuestionnaireDTO>(
      `${this.basePath}/questionnaire/questionnaire/read/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionnaireUpdatePost(
    body?: QuestionnaireDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireQuestionnaireUpdatePost(
    body?: QuestionnaireDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireQuestionnaireUpdatePost(
    body?: QuestionnaireDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireQuestionnaireUpdatePost(
    body?: QuestionnaireDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/questionnaire/questionnaire/update`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param questionnaireId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireQuestionsAndAnswersQuestionnaireIdGet(
    questionnaireId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<QuestionAndAnswersDTO>>;
  public questionnaireQuestionsAndAnswersQuestionnaireIdGet(
    questionnaireId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<QuestionAndAnswersDTO>>>;
  public questionnaireQuestionsAndAnswersQuestionnaireIdGet(
    questionnaireId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<QuestionAndAnswersDTO>>>;
  public questionnaireQuestionsAndAnswersQuestionnaireIdGet(
    questionnaireId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (questionnaireId === null || questionnaireId === undefined) {
      throw new Error(
        "Required parameter questionnaireId was null or undefined when calling questionnaireQuestionsAndAnswersQuestionnaireIdGet."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<QuestionAndAnswersDTO>>(
      `${
        this.basePath
      }/questionnaire/questions-and-answers/${encodeURIComponent(
        String(questionnaireId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportCreatePost(
    body?: ReportDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<string>;
  public questionnaireReportCreatePost(
    body?: ReportDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public questionnaireReportCreatePost(
    body?: ReportDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public questionnaireReportCreatePost(
    body?: ReportDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<string>(
      `${this.basePath}/questionnaire/report/create`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userIdentityId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportCvGet(
    userIdentityId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public questionnaireReportCvGet(
    userIdentityId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public questionnaireReportCvGet(
    userIdentityId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public questionnaireReportCvGet(
    userIdentityId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (userIdentityId !== undefined && userIdentityId !== null) {
      queryParameters = queryParameters.set(
        "userIdentityId",
        <any>userIdentityId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(`${this.basePath}/questionnaire/report/cv`, {
      params: queryParameters,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportDeleteIdDelete(
    id: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireReportDeleteIdDelete(
    id: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireReportDeleteIdDelete(
    id: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireReportDeleteIdDelete(
    id: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        "Required parameter id was null or undefined when calling questionnaireReportDeleteIdDelete."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/questionnaire/report/delete/${encodeURIComponent(
        String(id)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<ReportDTO>>;
  public questionnaireReportListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ReportDTO>>>;
  public questionnaireReportListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ReportDTO>>>;
  public questionnaireReportListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ReportDTO>>(
      `${this.basePath}/questionnaire/report/list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userIdentityId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportQuestionsAndAnswersGet(
    userIdentityId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public questionnaireReportQuestionsAndAnswersGet(
    userIdentityId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public questionnaireReportQuestionsAndAnswersGet(
    userIdentityId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public questionnaireReportQuestionsAndAnswersGet(
    userIdentityId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (userIdentityId !== undefined && userIdentityId !== null) {
      queryParameters = queryParameters.set(
        "userIdentityId",
        <any>userIdentityId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(
      `${this.basePath}/questionnaire/report/questions-and-answers`,
      {
        params: queryParameters,
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userIdentityId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportSupportedEmploymentGet(
    userIdentityId?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public questionnaireReportSupportedEmploymentGet(
    userIdentityId?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public questionnaireReportSupportedEmploymentGet(
    userIdentityId?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public questionnaireReportSupportedEmploymentGet(
    userIdentityId?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (userIdentityId !== undefined && userIdentityId !== null) {
      queryParameters = queryParameters.set(
        "userIdentityId",
        <any>userIdentityId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(
      `${this.basePath}/questionnaire/report/supported-employment`,
      {
        params: queryParameters,
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportTimeSpentGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Blob>;
  public questionnaireReportTimeSpentGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Blob>>;
  public questionnaireReportTimeSpentGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Blob>>;
  public questionnaireReportTimeSpentGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get(
      `${this.basePath}/questionnaire/report/time-spent`,
      {
        responseType: "blob",
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireReportUpdatePut(
    body?: ReportDTO,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireReportUpdatePut(
    body?: ReportDTO,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireReportUpdatePut(
    body?: ReportDTO,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireReportUpdatePut(
    body?: ReportDTO,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/questionnaire/report/update`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param questionnaireId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public questionnaireUpsertAnswersPost(
    questionnaireId?: string,
    body?: Array<UpsertAnswerDTO>,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<any>;
  public questionnaireUpsertAnswersPost(
    questionnaireId?: string,
    body?: Array<UpsertAnswerDTO>,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public questionnaireUpsertAnswersPost(
    questionnaireId?: string,
    body?: Array<UpsertAnswerDTO>,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public questionnaireUpsertAnswersPost(
    questionnaireId?: string,
    body?: Array<UpsertAnswerDTO>,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (questionnaireId !== undefined && questionnaireId !== null) {
      queryParameters = queryParameters.set(
        "questionnaireId",
        <any>questionnaireId
      );
    }

    let headers = this.defaultHeaders;

    // authentication (Bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<any>(
      `${this.basePath}/questionnaire/upsert-answers`,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
