import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { LayoutModule } from '@angular/cdk/layout';
import { StagesOfLifeMenuComponent } from './stages-of-life-menu/stages-of-life-menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        HeaderComponent,
        StagesOfLifeMenuComponent,
        FooterComponent,
        HeaderMenuComponent,
        ModalComponent
    ],
    exports: [
        HeaderComponent,
        StagesOfLifeMenuComponent,
        FooterComponent,
        HeaderMenuComponent,
        ModalComponent,
    ]
})
export class SharedModule { }
