<div class="toggle-mobile-menu" (click)="toggleMobileMenu($event)" #mobileMenuToggle>
  <div class="line-1" #l1></div>
  <div class="line-2" #l2></div>
  <div class="line-3" #l3></div>
</div>
<div (click)="setTheme(true)" class="theme-toggler" #themeToggler>
</div>
<div class="welcome-message" *ngIf="state.isLoggedIn">Welkom {{state.isCoach ? 'begeleider' : 'deelnemer'}}</div>
<ul class="mobile-menu" #mobileMenu>
  <form class="search-box-mobile" #mobileSearchBox>
    <input (keydown)="onKeydown($event)" type="text" placeholder="Zoeken…" #mobileSearchInput>
    <button (click)="search()">zoek</button>
  </form>
  <li class="welcome-message-mobile" *ngIf="state.isLoggedIn">Welkom {{state.isCoach ? 'begeleider' : 'deelnemer'}}</li>
  <li routerLink="" (click)="toggleMobileMenu($event)">Home</li>
  <li (click)="toggleMobileSubMenu($event,'sub-menu-autist')">Ik heb een vraag over...<i
      class="material-icons">chevron_right</i>
    <ul class="sub-menu" id="sub-menu-autist">
      <li class="back" (click)="toggleMobileSubMenu($event,null)"><i
          class="material-icons back-icon">chevron_left</i>terug</li>
      <li (click)="toggleMobileMenu($event)" routerLink="onderwijs">{{wpLevensFases.onderwijs.name}}</li>
      <li (click)="toggleMobileMenu($event)" routerLink="werkzoeken">{{wpLevensFases.werkzoeken.name}}</li>
      <li (click)="toggleMobileMenu($event)" routerLink="werkhebben">{{wpLevensFases.werkhebben.name}}</li>
      <li (click)="toggleMobileMenu($event)" routerLink="uitgeschakeld">{{wpLevensFases.uitgeschakeld.name}}</li>
    </ul>
  </li>
  <li *ngFor="let item of wpHoofdMenu.items" (click)="toggleMobileSubMenuIfMenuHasChildren($event, item)">
    {{item.title}}<i *ngIf="item.children.length>0" class="material-icons">chevron_right</i>
    <ul *ngIf="item.children.length > 0" class="sub-menu" [id]="'sub-menu-'+item.slug">
      <li class="back" (click)="toggleMobileSubMenu($event,null)"><i
          class="material-icons back-icon">chevron_left</i>terug</li>
      <li class="parent">{{item.title}}</li>
      <li *ngFor="let subitem of item.children" (click)="toggleMobileMenu($event)" [routerLink]="'page/'+subitem.slug">
        {{subitem.title}}</li>
    </ul>
  </li>
  <li (click)="toggleMobileMenu($event)" *ngIf="!isUserLoggedIn" (click)="showAuthModal()">Inloggen</li>
  <li (click)="toggleMobileMenu($event)" *ngIf="isUserLoggedIn" routerLink="mijnprofiel">Profiel</li>
  <li (click)="toggleMobileMenu($event)" *ngIf="isUserLoggedIn" (click)="doLogout()">Uitloggen</li>
  <li (click)="toggleMobileMenu($event)" *ngIf="isUserAdmin" routerLink="admin">Admin</li>
</ul>
<ul class="desktop-menu" #desktopMenu>
  <li class="item-home" routerLink="">Home</li>
  <li class="item-autist">Ik heb een vraag over...<i class="material-icons menu-down">keyboard_arrow_down</i>
    <ul class="sub-menu">
      <li (click)="toggleMobileMenu($event)" routerLink="onderwijs">{{wpLevensFases.onderwijs.name}}</li>
      <li (click)="toggleMobileMenu($event)" routerLink="werkzoeken">{{wpLevensFases.werkzoeken.name}}</li>
      <li (click)="toggleMobileMenu($event)" routerLink="werkhebben">{{wpLevensFases.werkhebben.name}}</li>
      <li (click)="toggleMobileMenu($event)" routerLink="uitgeschakeld">{{wpLevensFases.uitgeschakeld.name}}</li>
    </ul>
  </li>
  <li *ngFor="let item of wpHoofdMenu.items" [routerLink]="item.link">{{item.title}}<i *ngIf="item.children.length>0"
      class="material-icons menu-down">keyboard_arrow_down</i>
    <ul *ngIf="item.children.length>0" class="sub-menu">
      <li *ngFor="let subitem of item.children" (click)="toggleMobileMenu($event)" [routerLink]="subitem.link">
        {{subitem.title}}</li>
    </ul>
  </li>

  <li *ngIf="!isUserLoggedIn" (click)="showAuthModal()">Inloggen</li>
  <li *ngIf="isUserLoggedIn" routerLink="mijnprofiel">Profiel</li>
  <li *ngIf="isUserLoggedIn" (click)="doLogout()">Uitloggen</li>
  <li *ngIf="isUserAdmin" routerLink="admin">Admin</li>
  <li class="item-search" (click)="toggleSearchBox($event)" #desktopSearchLi>
    <i class="material-icons">search</i>
  </li>
</ul>
<form class="search-box" #desktopSearchBox>
  <input (keydown)="onKeydown($event)" type="text" placeholder="Zoeken…" #desktopSearchInput>
  <button type="submit" (click)="search()">zoek</button>
</form>