import { Component, OnInit } from '@angular/core';
import { User } from 'oidc-client';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [HeaderMenuComponent, RouterLink]
})
export class HeaderComponent implements OnInit {
  user: User;
  loggedIn = false;

  constructor() {
  }

  ngOnInit() {
  }
}
