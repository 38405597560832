import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
  { path: '', loadChildren: () => import('./views/wordpress/wordpress.module').then(m => m.WordpressModule) },
  { path: 'auth', loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule) },
  { path: 'admin', loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule), canLoad: [AuthGuardService] },
  { path: 'mijnprofiel', loadChildren: () => import('./views/my-profile/my-profile.module').then(m => m.MyProfileModule), canLoad: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
