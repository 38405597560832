/* src/app/views/shared/footer/footer.component.scss */
.colored {
  --white: white;
  --body-text: rgb(69, 69, 69);
  --header-text: rgb(2, 162, 167);
  --header-text-hover: rgb(2, 127, 131);
  --header-text-alternate: rgb(72, 193, 173);
  --banner-bg: rgba(2, 162, 167, 0.8);
  --home-page-background: rgb(237, 245, 241);
  --home-page-background-0: rgba(233, 241, 247, 0);
  --home-page-background-100: rgb(233, 241, 247);
  --search-page: rgb(233, 241, 247);
  --footer-background: rgb(197, 197, 197);
  --footer-background-darker: rgb(159, 166, 170);
  --grey-filter: grayscale(0%);
  --bigpic: url(/assets/img/newstyle/achievement-adult-agreement-1180.png);
  --logo-asset: url(/assets/svg/wwa_logo_color.svg);
  --loading-img: url(/assets/img/loading_clr.png);
  --profiel-tab: rgb(2, 162, 167);
  --profiel-tab-text: rgb(69, 69, 69);
  --onderwijs-tab: rgb(255, 218, 113);
  --onderwijs-tab-mob: rgb(255, 218, 113);
  --onderwijs-tab-text: rgb(69, 69, 69);
  --onderwijs-page: hsl(150, 29%, 95%);
  --werkhebben-tab: rgb(213, 219, 97);
  --werkhebben-tab-mob: rgb(213, 219, 97);
  --werkhebben-tab-text: rgb(69, 69, 69);
  --werkhebben-page: rgb(238, 245, 242);
  --werkzoeken-tab: rgb(243, 120, 104);
  --werkzoeken-tab-mob: rgb(243, 120, 104);
  --werkzoeken-tab-text: rgb(69, 69, 69);
  --werkzoeken-page: rgb(238, 245, 242);
  --nietwerken-tab: rgb(77, 199, 179);
  --nietwerken-tab-mob: rgb(77, 199, 179);
  --nietwerken-tab-text: rgb(69, 69, 69);
  --nietwerken-page: rgb(237, 245, 241);
  --profielvragen-tab: rgb(213, 219, 97);
  --editaccount-tab: rgb(255, 218, 113);
  --rapportages-tab: rgb(243, 120, 104);
  --wetenschappelijk-tab: rgb(77, 199, 179);
  --button-blue: rgb(51, 128, 187);
  --button-blue-hover: rgb(52, 112, 158);
  --button-yellow: rgb(254, 196, 35);
  --button-yellow-hover: rgb(255, 216, 108);
  --green-accept: $green2-base;
  --red-deny: rgb(192, 10, 10);
  --red-deny-lighter-10: rgb(224, 71, 71);
  --yellow-info: $yellow-base;
  --black-33: rgb(32, 33, 36);
  --orange-60: rgba(243, 145, 25, 0.6);
}
.black-and-white {
  --white: white;
  --body-text: rgb(53, 53, 53);
  --header-text: rgb(69, 69, 69);
  --header-text-hover: rgb(85, 85, 85);
  --header-text-alternate: rgb(69, 69, 69);
  --banner-bg: rgba(160, 160, 160, 0.8);
  --profiel-tab: rgb(223, 223, 223);
  --onderwijs-tab: rgb(223, 223, 223);
  --onderwijs-tab-selected: white;
  --onderwijs-tab-mob:rgb(223, 223, 223);
  --onderwijs-tab-text: black;
  --onderwijs-page: rgb(240, 240, 240);
  --werkhebben-tab: rgb(223, 223, 223);
  --werkhebben-tab-selected: white;
  --werkhebben-tab-mob: white;
  --werkhebben-tab-text: black;
  --werkhebben-page: rgb(240, 240, 240);
  --werkzoeken-tab: rgb(223, 223, 223);
  --werkzoeken-tab-selected: white;
  --werkzoeken-tab-mob:white;
  --werkzoeken-tab-text: black;
  --werkzoeken-page: rgb(240, 240, 240);
  --nietwerken-tab: rgb(223, 223, 223);
  --nietwerken-tab-selected: white;
  --nietwerken-tab-mob: rgb(223, 223, 223);
  --nietwerken-tab-text: black;
  --nietwerken-page: rgb(240, 240, 240);
  --profielvragen-tab: rgb(223, 223, 223);
  --editaccount-tab: rgb(223, 223, 223);
  --rapportages-tab: rgb(223, 223, 223);
  --wetenschappelijk-tab: rgb(223, 223, 223);
  --button-blue: rgb(146, 146, 146);
  --button-blue-hover: rgb(189, 189, 189);
  --button-yellow: rgb(206, 206, 206);
  --button-yellow-hover: rgb(235, 235, 235);
  --green-accept: $green2-base;
  --red-deny: rgb(192, 10, 10);
  --red-deny-lighter-10: rgb(224, 71, 71);
  --yellow-info: $yellow-base;
  --black-33: rgb(41, 41, 41);
  --orange-60: rgba(172, 172, 172, .6);
  --home-page-background: rgb(240, 240, 240);
  --home-page-background-0: rgba(240, 240, 240, 0);
  --home-page-background-100: rgb(240, 240, 240);
  --search-page: rgb(240, 240, 240);
  --footer-background: rgb(197, 197, 197);
  --footer-background-darker: rgb(155, 155, 155);
  --grey-filter: grayscale(100%);
  --logo-asset: url(/assets/svg/wwa_logo.svg);
  --loading-img: url(/assets/img/loading.png);
  --bigpic: url(/assets/img/newstyle/achievement-adult-agreement-1180-bw.png);
}
footer {
  background: var(--footer-background);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  footer {
    background: rgb(197, 197, 197);
  }
}
footer .footer-logo {
  margin-top: -12px;
}
footer {
  width: 100%;
  color: white;
  padding: 20px 20px 5px 20px;
  font-size: 70%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  footer {
    position: fixed;
    bottom: 0px;
    width: 75%;
    left: 50%;
    margin-left: -37.5%;
    margin-bottom: 0px !important;
  }
}
footer .logosRow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}
footer p {
  margin-bottom: 0px;
}
footer a {
  color: var(--header-text);
}
footer .footerLogo {
  width: 100%;
  max-width: 12.5%;
  float: left;
}
footer .footerLogo img {
  filter: var(--grey-filter);
  max-width: 100%;
  height: auto;
  max-height: 2.5rem;
}
footer .footerLogo.autismefonds img {
  max-width: 70%;
}
footer .footerLogo.samenDoen {
  text-align: center;
}
footer .footerLogo.autismefonds {
  padding-left: 16px;
}
footer .ico-label {
  float: left;
  padding-right: 7px;
  padding-top: 7px;
}
footer .ico-list li {
  float: left;
}
footer .ico-list li .ico {
  display: block;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-repeat: no-repeat;
  text-indent: -999em;
  filter: var(--grey-filter);
}
footer .ico-list li .ico.instagram {
  background-image: url(/assets/img/ico/ico-instagram.png);
}
footer .ico-list li .ico.facebook {
  background-image: url(/assets/img/ico/ico-facebook.png);
}
footer .ico-list li .ico.twitter {
  background-image: url(/assets/img/ico/ico-twitter.png);
}
footer .ico-list li .ico.youtube {
  background-image: url(/assets/img/ico/ico-youtube.png);
}
footer .ico-list li .ico.phone {
  background-image: url(/assets/img/ico/ico-phone.png);
}
footer .ico-list li .ico.email {
  background-image: url(/assets/img/ico/ico-email.png);
}
footer .row.copyright {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--footer-background-darker);
}
footer .pipe {
  color: var(--footer-background-darker);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .footer-profiel {
    display: none;
  }
}
/*# sourceMappingURL=footer.component.css.map */
