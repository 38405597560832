/* src/app/views/shared/header-menu/header-menu.component.scss */
.colored {
  --white: white;
  --body-text: rgb(69, 69, 69);
  --header-text: rgb(2, 162, 167);
  --header-text-hover: rgb(2, 127, 131);
  --header-text-alternate: rgb(72, 193, 173);
  --banner-bg: rgba(2, 162, 167, 0.8);
  --home-page-background: rgb(237, 245, 241);
  --home-page-background-0: rgba(233, 241, 247, 0);
  --home-page-background-100: rgb(233, 241, 247);
  --search-page: rgb(233, 241, 247);
  --footer-background: rgb(197, 197, 197);
  --footer-background-darker: rgb(159, 166, 170);
  --grey-filter: grayscale(0%);
  --bigpic: url(/assets/img/newstyle/achievement-adult-agreement-1180.png);
  --logo-asset: url(/assets/svg/wwa_logo_color.svg);
  --loading-img: url(/assets/img/loading_clr.png);
  --profiel-tab: rgb(2, 162, 167);
  --profiel-tab-text: rgb(69, 69, 69);
  --onderwijs-tab: rgb(255, 218, 113);
  --onderwijs-tab-mob: rgb(255, 218, 113);
  --onderwijs-tab-text: rgb(69, 69, 69);
  --onderwijs-page: hsl(150, 29%, 95%);
  --werkhebben-tab: rgb(213, 219, 97);
  --werkhebben-tab-mob: rgb(213, 219, 97);
  --werkhebben-tab-text: rgb(69, 69, 69);
  --werkhebben-page: rgb(238, 245, 242);
  --werkzoeken-tab: rgb(243, 120, 104);
  --werkzoeken-tab-mob: rgb(243, 120, 104);
  --werkzoeken-tab-text: rgb(69, 69, 69);
  --werkzoeken-page: rgb(238, 245, 242);
  --nietwerken-tab: rgb(77, 199, 179);
  --nietwerken-tab-mob: rgb(77, 199, 179);
  --nietwerken-tab-text: rgb(69, 69, 69);
  --nietwerken-page: rgb(237, 245, 241);
  --profielvragen-tab: rgb(213, 219, 97);
  --editaccount-tab: rgb(255, 218, 113);
  --rapportages-tab: rgb(243, 120, 104);
  --wetenschappelijk-tab: rgb(77, 199, 179);
  --button-blue: rgb(51, 128, 187);
  --button-blue-hover: rgb(52, 112, 158);
  --button-yellow: rgb(254, 196, 35);
  --button-yellow-hover: rgb(255, 216, 108);
  --green-accept: $green2-base;
  --red-deny: rgb(192, 10, 10);
  --red-deny-lighter-10: rgb(224, 71, 71);
  --yellow-info: $yellow-base;
  --black-33: rgb(32, 33, 36);
  --orange-60: rgba(243, 145, 25, 0.6);
}
.black-and-white {
  --white: white;
  --body-text: rgb(53, 53, 53);
  --header-text: rgb(69, 69, 69);
  --header-text-hover: rgb(85, 85, 85);
  --header-text-alternate: rgb(69, 69, 69);
  --banner-bg: rgba(160, 160, 160, 0.8);
  --profiel-tab: rgb(223, 223, 223);
  --onderwijs-tab: rgb(223, 223, 223);
  --onderwijs-tab-selected: white;
  --onderwijs-tab-mob:rgb(223, 223, 223);
  --onderwijs-tab-text: black;
  --onderwijs-page: rgb(240, 240, 240);
  --werkhebben-tab: rgb(223, 223, 223);
  --werkhebben-tab-selected: white;
  --werkhebben-tab-mob: white;
  --werkhebben-tab-text: black;
  --werkhebben-page: rgb(240, 240, 240);
  --werkzoeken-tab: rgb(223, 223, 223);
  --werkzoeken-tab-selected: white;
  --werkzoeken-tab-mob:white;
  --werkzoeken-tab-text: black;
  --werkzoeken-page: rgb(240, 240, 240);
  --nietwerken-tab: rgb(223, 223, 223);
  --nietwerken-tab-selected: white;
  --nietwerken-tab-mob: rgb(223, 223, 223);
  --nietwerken-tab-text: black;
  --nietwerken-page: rgb(240, 240, 240);
  --profielvragen-tab: rgb(223, 223, 223);
  --editaccount-tab: rgb(223, 223, 223);
  --rapportages-tab: rgb(223, 223, 223);
  --wetenschappelijk-tab: rgb(223, 223, 223);
  --button-blue: rgb(146, 146, 146);
  --button-blue-hover: rgb(189, 189, 189);
  --button-yellow: rgb(206, 206, 206);
  --button-yellow-hover: rgb(235, 235, 235);
  --green-accept: $green2-base;
  --red-deny: rgb(192, 10, 10);
  --red-deny-lighter-10: rgb(224, 71, 71);
  --yellow-info: $yellow-base;
  --black-33: rgb(41, 41, 41);
  --orange-60: rgba(172, 172, 172, .6);
  --home-page-background: rgb(240, 240, 240);
  --home-page-background-0: rgba(240, 240, 240, 0);
  --home-page-background-100: rgb(240, 240, 240);
  --search-page: rgb(240, 240, 240);
  --footer-background: rgb(197, 197, 197);
  --footer-background-darker: rgb(155, 155, 155);
  --grey-filter: grayscale(100%);
  --logo-asset: url(/assets/svg/wwa_logo.svg);
  --loading-img: url(/assets/img/loading.png);
  --bigpic: url(/assets/img/newstyle/achievement-adult-agreement-1180-bw.png);
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: var(--body-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body,
  html {
    color: rgb(69, 69, 69);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(2, 162, 167);
  }
}
.background {
  width: 100%;
  background: var(--home-page-background);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .background {
    background: hsl(150, 29%, 95%);
  }
}
.background-submenu {
  background: var(--nietwerken-tab);
  background: -moz-linear-gradient(left, var(--onderwijs-tab) 0%, var(--onderwijs-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background: -webkit-linear-gradient(left, var(--onderwijs-tab) 0%, var(--onderwijs-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background:
    linear-gradient(
      to right,
      var(--onderwijs-tab) 0%,
      var(--onderwijs-tab) 50%,
      var(--nietwerken-tab) 51%,
      var(--nietwerken-tab) 100%);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .background-submenu {
    background: var(rgb(77, 199, 179));
    background: -moz-linear-gradient(left, rgb(255, 218, 113) 0%, rgb(255, 218, 113) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background: -webkit-linear-gradient(left, rgb(255, 218, 113) 0%, rgb(255, 218, 113) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background:
      linear-gradient(
        to right,
        rgb(255, 218, 113) 0%,
        rgb(255, 218, 113) 50%,
        rgb(77, 199, 179) 51%,
        rgb(77, 199, 179) 100%);
  }
}
.background-submenu-profile {
  background: var(--nietwerken-tab);
  background: -moz-linear-gradient(left, var(--werkhebben-tab) 0%, var(--werkhebben-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background: -webkit-linear-gradient(left, var(--werkhebben-tab) 0%, var(--werkhebben-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background:
    linear-gradient(
      to right,
      var(--werkhebben-tab) 0%,
      var(--werkhebben-tab) 50%,
      var(--nietwerken-tab) 51%,
      var(--nietwerken-tab) 100%);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .background-submenu-profile {
    background: rgb(77, 199, 179);
    background: -moz-linear-gradient(left, rgb(213, 219, 97) 0%, rgb(213, 219, 97) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background: -webkit-linear-gradient(left, rgb(213, 219, 97) 0%, rgb(213, 219, 97) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background:
      linear-gradient(
        to right,
        rgb(213, 219, 97) 0%,
        rgb(213, 219, 97) 50%,
        rgb(77, 199, 179) 51%,
        rgb(77, 199, 179) 100%);
  }
}
.container {
  max-width: 1180px;
  padding: 0;
}
.container > * img,
.container > * iframe {
  filter: var(--grey-filter);
}
.row {
  display: flex;
  flex-direction: row;
  margin: 0;
}
.col {
  display: flex;
  flex-direction: column;
}
.hidden {
  display: none;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loadingSpinner:before {
  content: "";
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background: var(--loading-img) no-repeat;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .loadingSpinner:before {
    background: url(/assets/img/loading_clr.png) no-repeat;
  }
}
.loadingSpinner:before {
  background-size: contain;
  animation: spinner 1.75s linear infinite;
  margin: 0px auto;
  margin-top: 10px;
}
app-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
app-home,
app-free-page,
app-wp-page,
app-wp-pages,
app-wp-pages-by-search,
app-edit-profile,
app-my-profile,
app-unauthorized,
app-rapportages,
app-add-comment,
app-comments,
app-coaches {
  flex: 1;
  max-width: 1180px;
  width: 100%;
  margin: 0px auto;
}
app-home,
app-edit-profile,
app-my-profile,
app-unauthorized,
app-rapportages {
  background: #ffffff;
}
.toggle-mobile-menu {
  position: fixed;
  right: 8px;
  top: 0px;
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background: white;
  border: none;
  float: right;
  margin: 10px;
}
.line-1,
.line-2,
.line-3 {
  position: absolute;
  background: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .line-1,
  .line-2,
  .line-3 {
    background: rgb(2, 162, 167);
  }
}
.line-1,
.line-2,
.line-3 {
  width: 25px;
  height: 4px;
  border-radius: 2px;
  left: 10px;
  transition: all 200ms;
}
.line-1 {
  top: 12px;
}
.line-2 {
  top: 20px;
}
.line-3 {
  top: 28px;
}
.mobile-menu {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 320px;
  width: 70vw;
  transition: all 500ms;
  float: right;
  position: fixed;
  right: -100vw;
  top: 0px;
  padding-top: 70px;
  z-index: -1;
  height: 100vh;
  overflow: hidden;
  background: white;
}
.mobile-menu li {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 15px 0 15px 30px;
  font-size: 115%;
  font-weight: 600;
  color: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mobile-menu li {
    color: rgb(2, 162, 167);
  }
}
.mobile-menu li {
  overflow: hidden;
  max-width: 320px;
  width: 100%;
  white-space: normal;
  border-top: 1px solid #eee;
}
.mobile-menu li i {
  position: absolute;
  right: 0;
  top: 30%;
}
.mobile-menu li .sub-menu {
  z-index: 1;
  transition: all 500ms;
  position: fixed;
  right: -100vw;
  top: 120px;
  list-style-type: none;
  display: flex;
  height: 0;
  flex-direction: column;
  height: calc(100vh - 20px);
  overflow: hidden;
  background: white;
  min-width: 320px;
  max-width: 320px;
  width: 70vw;
}
.mobile-menu li .sub-menu .parent {
  font-size: 115%;
  font-weight: 700;
  padding: 8px 8px 8px 18px;
}
.mobile-menu li .sub-menu .back {
  border-top: 0;
  line-height: 50px;
  text-transform: uppercase;
  font-size: 90%;
  font-weight: 400;
}
.mobile-menu li .sub-menu .back i {
  position: relative;
  font-size: 300%;
  line-height: 50px;
  margin-left: -15px;
}
.mobile-menu li .sub-menu li {
  font-size: 100%;
  cursor: pointer;
  color: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mobile-menu li .sub-menu li {
    color: rgb(2, 162, 167);
  }
}
.mobile-menu li .sub-menu li {
  background: white;
  padding: 7px;
  padding-left: 20px;
}
.mobile-menu li .sub-menu li:hover {
  background: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mobile-menu li .sub-menu li:hover {
    background: rgb(2, 162, 167);
  }
}
.mobile-menu li .sub-menu li:hover {
  color: white;
}
.mobile-menu li:hover {
  background: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mobile-menu li:hover {
    background: rgb(2, 162, 167);
  }
}
.mobile-menu li:hover {
  color: white;
}
.mobile-menu li.welcome-message-mobile {
  color: black;
}
.mobile-menu .mobile-menu li:hover {
  background: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mobile-menu .mobile-menu li:hover {
    background: rgb(2, 162, 167);
  }
}
.mobile-menu .mobile-menu li:hover {
  color: white;
}
.search-box-mobile {
  display: block;
  font-size: 14px;
  width: 290px;
  margin-left: 20px;
  background: white;
  padding: 10px;
}
.search-box-mobile input {
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  width: calc(100% - 76px);
  font-weight: 300;
  line-height: 2.14;
  color: #262626;
  padding: 2px 16px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border: solid 1px var(--header-text-alternate);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-box-mobile input {
    border: solid 1px rgb(72, 193, 173);
  }
}
.search-box-mobile button {
  display: table-cell;
  vertical-align: top;
  height: 35px;
  width: 76px;
  font-weight: 600;
  line-height: 32px;
  color: #fff;
  background: var(--header-text-alternate);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-box-mobile button {
    background: rgb(72, 193, 173);
  }
}
.search-box-mobile button {
  padding: 0;
  margin: 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  cursor: pointer;
}
.search-box-mobile button:hover {
  background: var(--header-text-alternate) !important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-box-mobile button:hover {
    background: rgb(72, 193, 173) !important;
  }
}
.welcome-message {
  display: none;
  position: absolute;
  top: 10px;
  right: 80px;
  font-size: 0.97rem;
  font-weight: 700;
}
@media screen and (min-width: 1181px) {
  .toggle-mobile-menu {
    display: none;
  }
  .mobile-menu {
    display: none !important;
  }
  .desktop-menu {
    display: flex !important;
  }
  .welcome-message {
    display: block;
  }
}
.desktop-menu {
  position: absolute;
  top: 20px;
  right: 46px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu {
    right: 5px;
  }
}
.desktop-menu {
  display: none;
  justify-content: right;
  flex-direction: row;
  list-style-type: none;
  cursor: pointer;
  height: 40px;
  margin-top: 7px;
}
.desktop-menu li,
.desktop-menu .three-points {
  transition: all 200ms;
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 10px 7px 10px 7px;
  font-size: 0.97rem;
  font-weight: 700;
  color: var(--header-text);
  overflow: hidden;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu li,
  .desktop-menu .three-points {
    color: rgb(2, 162, 167);
    position: relative;
    overflow: visible;
  }
}
.desktop-menu li,
.desktop-menu .three-points {
  white-space: nowrap;
}
.desktop-menu li .sub-menu li:hover,
.desktop-menu .three-points .sub-menu li:hover {
  background: var(--header-text-alternate);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu li .sub-menu li:hover,
  .desktop-menu .three-points .sub-menu li:hover {
    background: rgb(72, 193, 173);
  }
}
.desktop-menu li .sub-menu li:hover,
.desktop-menu .three-points .sub-menu li:hover {
  color: white;
}
.desktop-menu li .sub-menu,
.desktop-menu .three-points .sub-menu {
  padding-top: 10px;
  list-style-type: none;
  list-style: none;
  position: absolute;
  list-style-type: none;
  display: none;
  flex-direction: column;
  min-width: 168px;
  margin-top: 30px;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
}
.desktop-menu li .sub-menu li,
.desktop-menu .three-points .sub-menu li {
  color: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu li .sub-menu li,
  .desktop-menu .three-points .sub-menu li {
    color: rgb(2, 162, 167);
  }
}
.desktop-menu li .sub-menu li,
.desktop-menu .three-points .sub-menu li {
  background: white;
  padding: 13px 13px 13px 17px;
  font-weight: 600;
}
.desktop-menu li:hover {
  color: var(--header-text-hover);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu li:hover {
    color: rgb(2, 127, 131);
  }
}
.desktop-menu li:hover .sub-menu {
  display: flex;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu li:hover .sub-menu {
    left: 0;
  }
}
.item-search {
  background: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .item-search {
    background: rgb(2, 162, 167);
  }
}
.item-search {
  height: 36px;
  width: 36px;
  border-radius: 20px;
  color: white !important;
}
.item-search i {
  font-size: 1.5rem;
  margin: -3px 0 0 0px;
}
.menu-down {
  font-size: 0.995rem;
  font-weight: 800;
  padding-top: 0.4rem;
  padding-left: 2px;
}
.search-box {
  display: none;
  position: absolute;
  right: 0;
  top: 70px;
  font-size: 14px;
  width: 320px;
  background: white;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
}
.search-box input {
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  width: calc(100% - 76px);
  font-weight: 300;
  line-height: 2.14;
  color: #262626;
  padding: 2px 16px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border: solid 1px var(--header-text-alternate);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-box input {
    border: solid 1px rgb(72, 193, 173);
  }
}
.search-box button {
  display: table-cell;
  vertical-align: top;
  height: 35px;
  width: 76px;
  font-weight: 600;
  line-height: 32px;
  color: #fff;
  background: var(--header-text-alternate);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-box button {
    background: rgb(72, 193, 173);
  }
}
.search-box button {
  padding: 0;
  margin: 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  cursor: pointer;
}
.search-box button:hover {
  background: var(--header-text-alternate) !important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-box button:hover {
    background: rgb(72, 193, 173) !important;
  }
}
.theme-toggler {
  display: block;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .theme-toggler {
    display: none;
  }
}
.theme-toggler {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 0px;
  width: 36px;
  height: 36px;
  background-image: url(/assets/svg/colored.svg);
  background-size: 145% 145%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 1180px) {
  .theme-toggler {
    top: -45px;
    right: 60px;
    width: 36px;
    height: 36px;
    background-size: 100% 100%;
  }
}
/*# sourceMappingURL=header-menu.component.css.map */
