<div class="background-submenu container">
  <div class="subheader" #subHeader>
    <!-- [start] only visible when user IS logged in-->
    <div *ngIf="state.isLoggedIn" class="profiel-tab" routerLink="/mijnprofiel">
      <div class="bg"></div>
      <div *ngIf="state.selectedPage==='mijnprofiel'" class="tab-selected"></div>
      <div class="icon"></div>
      <div class="title">Mijn WerkWeb</div>
    </div>
    <!-- [end] only visible whenn user IS logged in-->
    <div class="onderwijs-tab" routerLink="/onderwijs" >
      <div class="bg"></div>
      <div *ngIf="state.selectedPage===WpMenuEnum[WpMenuEnum.onderwijs]" class="tab-selected"></div>
      <div class="icon"></div>
      <div class="title">{{menus.onderwijs.name}}</div>
    </div>
    <div class="werkzoeken-tab" routerLink="/werkzoeken" >
      <div class="bg"></div><!-- [ngClass]="{'selected': WpMenuEnum[WpMenuEnum.werkzoeken]}" -->
      <div *ngIf="state.selectedPage===WpMenuEnum[WpMenuEnum.werkzoeken]" class="tab-selected"></div>
      <div class="icon"></div>
      <div class="title">{{menus.werkzoeken.name}}</div>
    </div>
    <div class="werkhebben-tab" routerLink="/werkhebben" >
      <div class="bg"></div>
      <div *ngIf="state.selectedPage===WpMenuEnum[WpMenuEnum.werkhebben]" class="tab-selected"></div>
      <div class="icon"></div>
      <div class="title">{{menus.werkhebben.name}}</div>
      
    </div>
    <div class="uitgeschakeld-tab" routerLink="/uitgeschakeld" >
      <div class="bg"></div>
      <div *ngIf="state.selectedPage===WpMenuEnum[WpMenuEnum.uitgeschakeld]" class="tab-selected"></div>
      <div class="icon"></div>
      <div class="title">{{menus.uitgeschakeld.name}}</div>
    </div>
  </div>
</div>
