/* src/app/views/shared/header/header.component.scss */
.colored {
  --white: white;
  --body-text: rgb(69, 69, 69);
  --header-text: rgb(2, 162, 167);
  --header-text-hover: rgb(2, 127, 131);
  --header-text-alternate: rgb(72, 193, 173);
  --banner-bg: rgba(2, 162, 167, 0.8);
  --home-page-background: rgb(237, 245, 241);
  --home-page-background-0: rgba(233, 241, 247, 0);
  --home-page-background-100: rgb(233, 241, 247);
  --search-page: rgb(233, 241, 247);
  --footer-background: rgb(197, 197, 197);
  --footer-background-darker: rgb(159, 166, 170);
  --grey-filter: grayscale(0%);
  --bigpic: url(/assets/img/newstyle/achievement-adult-agreement-1180.png);
  --logo-asset: url(/assets/svg/wwa_logo_color.svg);
  --loading-img: url(/assets/img/loading_clr.png);
  --profiel-tab: rgb(2, 162, 167);
  --profiel-tab-text: rgb(69, 69, 69);
  --onderwijs-tab: rgb(255, 218, 113);
  --onderwijs-tab-mob: rgb(255, 218, 113);
  --onderwijs-tab-text: rgb(69, 69, 69);
  --onderwijs-page: hsl(150, 29%, 95%);
  --werkhebben-tab: rgb(213, 219, 97);
  --werkhebben-tab-mob: rgb(213, 219, 97);
  --werkhebben-tab-text: rgb(69, 69, 69);
  --werkhebben-page: rgb(238, 245, 242);
  --werkzoeken-tab: rgb(243, 120, 104);
  --werkzoeken-tab-mob: rgb(243, 120, 104);
  --werkzoeken-tab-text: rgb(69, 69, 69);
  --werkzoeken-page: rgb(238, 245, 242);
  --nietwerken-tab: rgb(77, 199, 179);
  --nietwerken-tab-mob: rgb(77, 199, 179);
  --nietwerken-tab-text: rgb(69, 69, 69);
  --nietwerken-page: rgb(237, 245, 241);
  --profielvragen-tab: rgb(213, 219, 97);
  --editaccount-tab: rgb(255, 218, 113);
  --rapportages-tab: rgb(243, 120, 104);
  --wetenschappelijk-tab: rgb(77, 199, 179);
  --button-blue: rgb(51, 128, 187);
  --button-blue-hover: rgb(52, 112, 158);
  --button-yellow: rgb(254, 196, 35);
  --button-yellow-hover: rgb(255, 216, 108);
  --green-accept: $green2-base;
  --red-deny: rgb(192, 10, 10);
  --red-deny-lighter-10: rgb(224, 71, 71);
  --yellow-info: $yellow-base;
  --black-33: rgb(32, 33, 36);
  --orange-60: rgba(243, 145, 25, 0.6);
}
.black-and-white {
  --white: white;
  --body-text: rgb(53, 53, 53);
  --header-text: rgb(69, 69, 69);
  --header-text-hover: rgb(85, 85, 85);
  --header-text-alternate: rgb(69, 69, 69);
  --banner-bg: rgba(160, 160, 160, 0.8);
  --profiel-tab: rgb(223, 223, 223);
  --onderwijs-tab: rgb(223, 223, 223);
  --onderwijs-tab-selected: white;
  --onderwijs-tab-mob:rgb(223, 223, 223);
  --onderwijs-tab-text: black;
  --onderwijs-page: rgb(240, 240, 240);
  --werkhebben-tab: rgb(223, 223, 223);
  --werkhebben-tab-selected: white;
  --werkhebben-tab-mob: white;
  --werkhebben-tab-text: black;
  --werkhebben-page: rgb(240, 240, 240);
  --werkzoeken-tab: rgb(223, 223, 223);
  --werkzoeken-tab-selected: white;
  --werkzoeken-tab-mob:white;
  --werkzoeken-tab-text: black;
  --werkzoeken-page: rgb(240, 240, 240);
  --nietwerken-tab: rgb(223, 223, 223);
  --nietwerken-tab-selected: white;
  --nietwerken-tab-mob: rgb(223, 223, 223);
  --nietwerken-tab-text: black;
  --nietwerken-page: rgb(240, 240, 240);
  --profielvragen-tab: rgb(223, 223, 223);
  --editaccount-tab: rgb(223, 223, 223);
  --rapportages-tab: rgb(223, 223, 223);
  --wetenschappelijk-tab: rgb(223, 223, 223);
  --button-blue: rgb(146, 146, 146);
  --button-blue-hover: rgb(189, 189, 189);
  --button-yellow: rgb(206, 206, 206);
  --button-yellow-hover: rgb(235, 235, 235);
  --green-accept: $green2-base;
  --red-deny: rgb(192, 10, 10);
  --red-deny-lighter-10: rgb(224, 71, 71);
  --yellow-info: $yellow-base;
  --black-33: rgb(41, 41, 41);
  --orange-60: rgba(172, 172, 172, .6);
  --home-page-background: rgb(240, 240, 240);
  --home-page-background-0: rgba(240, 240, 240, 0);
  --home-page-background-100: rgb(240, 240, 240);
  --search-page: rgb(240, 240, 240);
  --footer-background: rgb(197, 197, 197);
  --footer-background-darker: rgb(155, 155, 155);
  --grey-filter: grayscale(100%);
  --logo-asset: url(/assets/svg/wwa_logo.svg);
  --loading-img: url(/assets/img/loading.png);
  --bigpic: url(/assets/img/newstyle/achievement-adult-agreement-1180-bw.png);
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  color: var(--body-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body,
  html {
    color: rgb(69, 69, 69);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(2, 162, 167);
  }
}
.background {
  width: 100%;
  background: var(--home-page-background);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .background {
    background: hsl(150, 29%, 95%);
  }
}
.background-submenu {
  background: var(--nietwerken-tab);
  background: -moz-linear-gradient(left, var(--onderwijs-tab) 0%, var(--onderwijs-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background: -webkit-linear-gradient(left, var(--onderwijs-tab) 0%, var(--onderwijs-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background:
    linear-gradient(
      to right,
      var(--onderwijs-tab) 0%,
      var(--onderwijs-tab) 50%,
      var(--nietwerken-tab) 51%,
      var(--nietwerken-tab) 100%);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .background-submenu {
    background: var(rgb(77, 199, 179));
    background: -moz-linear-gradient(left, rgb(255, 218, 113) 0%, rgb(255, 218, 113) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background: -webkit-linear-gradient(left, rgb(255, 218, 113) 0%, rgb(255, 218, 113) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background:
      linear-gradient(
        to right,
        rgb(255, 218, 113) 0%,
        rgb(255, 218, 113) 50%,
        rgb(77, 199, 179) 51%,
        rgb(77, 199, 179) 100%);
  }
}
.background-submenu-profile {
  background: var(--nietwerken-tab);
  background: -moz-linear-gradient(left, var(--werkhebben-tab) 0%, var(--werkhebben-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background: -webkit-linear-gradient(left, var(--werkhebben-tab) 0%, var(--werkhebben-tab) 50%, var(--nietwerken-tab) 51%, var(--nietwerken-tab) 100%);
  background:
    linear-gradient(
      to right,
      var(--werkhebben-tab) 0%,
      var(--werkhebben-tab) 50%,
      var(--nietwerken-tab) 51%,
      var(--nietwerken-tab) 100%);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .background-submenu-profile {
    background: rgb(77, 199, 179);
    background: -moz-linear-gradient(left, rgb(213, 219, 97) 0%, rgb(213, 219, 97) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background: -webkit-linear-gradient(left, rgb(213, 219, 97) 0%, rgb(213, 219, 97) 50%, rgb(77, 199, 179) 51%, rgb(77, 199, 179) 100%);
    background:
      linear-gradient(
        to right,
        rgb(213, 219, 97) 0%,
        rgb(213, 219, 97) 50%,
        rgb(77, 199, 179) 51%,
        rgb(77, 199, 179) 100%);
  }
}
.container {
  max-width: 1180px;
  padding: 0;
}
.container > * img,
.container > * iframe {
  filter: var(--grey-filter);
}
.row {
  display: flex;
  flex-direction: row;
  margin: 0;
}
.col {
  display: flex;
  flex-direction: column;
}
.hidden {
  display: none;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.loadingSpinner:before {
  content: "";
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  background: var(--loading-img) no-repeat;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .loadingSpinner:before {
    background: url(/assets/img/loading_clr.png) no-repeat;
  }
}
.loadingSpinner:before {
  background-size: contain;
  animation: spinner 1.75s linear infinite;
  margin: 0px auto;
  margin-top: 10px;
}
app-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
app-home,
app-free-page,
app-wp-page,
app-wp-pages,
app-wp-pages-by-search,
app-edit-profile,
app-my-profile,
app-unauthorized,
app-rapportages,
app-add-comment,
app-comments,
app-coaches {
  flex: 1;
  max-width: 1180px;
  width: 100%;
  margin: 0px auto;
}
app-home,
app-edit-profile,
app-my-profile,
app-unauthorized,
app-rapportages {
  background: #ffffff;
}
button {
}
button {
  border: none;
  border-radius: 5px;
  background: rgb(51, 128, 187);
  font-weight: 500;
  vertical-align: bottom;
  color: white;
  font-size: 130%;
  max-width: 150px;
  padding: 5px 10px 6px 10px;
  cursor: pointer;
}
button:hover {
  background: rgb(52, 112, 158) !important;
}
.button {
  border: none;
  border-radius: 5px;
  background: rgb(51, 128, 187);
  font-weight: 500;
  vertical-align: bottom;
  color: white;
  font-size: 130%;
  max-width: 150px;
  padding: 5px 10px 6px 10px;
  cursor: pointer;
}
.button:hover {
  background: rgb(52, 112, 158) !important;
}
.header {
  min-height: 67px;
  max-height: 67px;
  width: 100%;
  z-index: 100;
}
@media (min-width: 1180px) {
  .header {
    min-height: 97px;
    max-height: 97px;
  }
}
.logo {
  cursor: pointer;
  position: absolute;
  background-image: var(--logo-asset);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .logo {
    background-image: url(/assets/svg/wwa_logo_color.svg);
  }
}
.logo {
  background-position-y: 10px;
  background-repeat: no-repeat;
  width: 124px;
  height: calc(97px - 15px);
  padding-top: 15px;
  left: 10px;
  top: 5px;
  transition: all 0.5s;
}
@media (max-width: 1180px) {
  .logo {
    width: 100px;
    height: 65px;
    top: -5px;
    left: 2px;
  }
}
app-loginoutsignup {
  margin: 25px 0 0 0;
  overflow: hidden;
}
app-searchbox {
  position: absolute;
  top: 56px;
  right: 50px;
}
@media (max-width: 1180px) {
  app-searchbox {
    top: 15px;
    right: 55px;
  }
}
app-header-menu {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 100%;
}
@media (max-width: 1180px) {
  app-header-menu {
    right: 0px;
    top: 60px;
  }
}
.hamburger {
  cursor: pointer;
  float: right;
  width: 50px;
  height: 50px;
  margin-top: 20px;
  margin-right: -5px;
  fill: var(--header-text);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hamburger {
    fill: rgb(2, 162, 167);
  }
}
@media (max-width: 1180px) {
  .hamburger {
    margin-top: 10px;
    width: 42px;
    height: 42px;
  }
}
.desktop-menu {
  display: block;
}
@media (max-width: 1180px) {
  .desktop-menu {
    display: none;
  }
}
.mobile-menu {
  display: none;
}
@media (max-width: 1180px) {
  .mobile-menu {
    display: block;
  }
}
.slogan {
  display: block;
  font-size: 100%;
  font-weight: 600;
  text-shadow: 0 1px 6px rgba(255, 255, 255, 0.651);
  color: black;
  text-align: center;
  margin-top: -35px;
  padding-left: 20px;
}
@media (min-width: 1180px) {
  .slogan {
    display: none;
  }
}
@media (max-width: 470px) {
  .slogan {
    display: none;
  }
}
/*# sourceMappingURL=header.component.css.map */
