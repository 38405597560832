<!-- modal is an existing class -->
<div *ngIf="isOpen()" class="custom-modal">
  <div class="custom-modal-overlay" (click)="bodyClick($event)"></div>
  <div class="custom-modal-body" (click)="bodyClick($event)">
    <div class="custom-modal-content">
      <a class="custom-modal-close-modal" (click)="close()" title="Sluit venster">X</a>
      <ng-content></ng-content>
    </div>
  </div>
</div>
